(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sportradar/assets/javascripts/sir-create-widget.js') >= 0) return;  svs.modules.push('/components/sport/sportradar/assets/javascripts/sir-create-widget.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  log
} = svs.core;
const {
  startSirScript,
  getWidgetClassByMatchId,
  doesWidgetTypeExist,
  setupWidgetRefrence,
  startTimer,
  clearTimer
} = svs.sport.sportradar;
const logger = new log.Logger('sir-api');
const logError = err => {
  if (err) {
    logger.warn("".concat(err.toString(), "(").concat(err.line, ", ").concat(err.column, ")").concat(err.stack ? " : ".concat(err.stack.toString()) : ''));
  }
};
const WidgetState = {
  Undefiend: 'Undefiend',
  Loading: 'Loading',
  Alive: 'Alive',
  Dead: 'Dead',
  Error: 'Error'
};
const isDead = state => {
  const dead = state === WidgetState.Dead || state === WidgetState.Error;
  return dead;
};
const createWidget = _ref => {
  let {
    matchId,
    widgetType,
    widgetProps,
    element,
    theme = 'DARK',
    onStateChange = () => {}
  } = _ref;
  if (!matchId) {
    const errMsg = 'Cant startWidget without matchId';
    logger.warn(errMsg);
    throw new Error(errMsg);
  } else if (!doesWidgetTypeExist(widgetType)) {
    const errMsg = "WidgetType: ".concat(widgetType, " does not exist, please use widgetTypes from svs.sport.sportradar.constants");
    logger.warn(errMsg);
    throw new Error(errMsg);
  } else if (!element) {
    const errMsg = 'Cant startWidget without a parent element';
    logger.warn(errMsg);
    throw new Error(errMsg);
  }
  let el;
  let state = WidgetState.Undefiend;
  const setState = newState => {
    if (!isDead(state)) {
      state = newState;
      if (isDead(newState)) {
        clearTimer(matchId, widgetType);
        if (el) {
          window.SIR('removeWidget', el);
          if (el.parentElement) {
            el.parentElement.removeChild(el);
          }
          el = null;
        }
      }
      onStateChange(newState);
    }
  };
  return {
    remove: () => {
      setState(WidgetState.Dead);
    },
    tryStart: () => {
      const mountSirScript = async () => {
        if (isDead(state)) {
          return;
        }
        if (!window.SIR) {
          await startSirScript();
        }
      };
      const createWidgetElement = () => {
        if (isDead(state)) {
          return;
        }
        el = document.createElement('div');
        const classNames = ["".concat(String(getWidgetClassByMatchId(matchId, widgetType, false))), 'sr-sir-widget'];
        switch (theme) {
          case 'LIGHT':
            classNames.push('sportradar-theme-light');
            break;
          case 'AUTO':
            classNames.push('sportradar-theme-auto');
            break;
          default:
            classNames.push('sportradar-theme-dark');
            break;
        }
        el.className = classNames.join(' ');
      };
      const addWidgetByElement = async () => {
        if (isDead(state)) {
          return;
        }
        setupWidgetRefrence(matchId, widgetType, el);
        const sirWidgetProps = _objectSpread(_objectSpread({}, widgetProps), {}, {
          matchId
        });
        return new Promise(resolve => {
          window.SIR('addWidget', el, widgetType, sirWidgetProps, () => {
            resolve();
          });
        });
      };
      const mountWidgetToDOM = () => {
        if (isDead(state)) {
          return;
        }
        if (el && element) {
          element.appendChild(el);
        }
      };
      try {
        (async () => {
          setState(WidgetState.Loading);
          await mountSirScript();
          createWidgetElement();
          await addWidgetByElement();
          mountWidgetToDOM();
          startTimer(matchId, widgetType, widgetProps);
          setState(WidgetState.Alive);
        })();
      } catch (err) {
        setState(WidgetState.Error);
        logError(err);
        throw err;
      }
    }
  };
};
setGlobal('svs.sport.sportradar.WidgetState', WidgetState);
setGlobal('svs.sport.sportradar.createWidget', createWidget);

 })(window);