(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sportradar/assets/javascripts/sir.js') >= 0) return;  svs.modules.push('/components/sport/sportradar/assets/javascripts/sir.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const rootWidgetClass = 'sr-widget';
const sirWidgetConfig = {
  language: 'se',
  clockType: '24',
  oddsType: 'eu',
  theme: false,
  debug: true
};
const tick = 3000;
const interval = {};
const widgetReferences = [];

const getSirWidgetConfig = () => sirWidgetConfig;
svs.sport.sportradar.startSirScript = () => new Promise((resolve, reject) => {
  let loadingTimeout;
  const clearTimer = () => {
    if (loadingTimeout) {
      clearTimeout(loadingTimeout);
    }
  };
  const sirOnError = error => {
    clearTimer();
    reject(error);
  };
  const sirOnLoad = () => {
    clearTimer();
    resolve('sir loaded');
  };
  try {
    const licenseID = svs.sportradar.data.licenseID;
    if (window.SIR) {
      resolve();
    } else {
      window.SIR = function () {
        (window.SIR.q = window.SIR.q || []).push(arguments);
      };
      window.SIR.l = new Date().getTime();
      window.SIR.o = getSirWidgetConfig();
      const windowSirScript = document.createElement('script');
      const firstScript = document.getElementsByTagName('script')[0];
      windowSirScript.async = 1;
      windowSirScript.onload = () => sirOnLoad();
      windowSirScript.onerror = e => sirOnError(e.error);
      windowSirScript.src = "https://widgets.sir.sportradar.com/".concat(licenseID, "/widgetloader");
      windowSirScript.setAttribute('n', 'SIR');
      firstScript.parentNode.insertBefore(windowSirScript, firstScript);
      loadingTimeout = setTimeout(() => {
        sirOnError(new Error('Failed to load sportradar resources'));
      }, 30000);
    }
  } catch (err) {
    sirOnError(err);
  }
});
svs.sport.sportradar.getKeyByMatchIdAndType = (matchId, widgetType) => "".concat(matchId, "-").concat(widgetType.replace(/\./ig, ''));
svs.sport.sportradar.getWidgetClassByMatchId = function (matchId, widgetType) {
  let useDot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return "".concat(useDot ? '.' : '').concat(rootWidgetClass, "-").concat(svs.sport.sportradar.getKeyByMatchIdAndType(matchId, widgetType));
};
svs.sport.sportradar.updateWidget = (matchId, widgetType, widgetProps, callback) => {
  widgetProps = _objectSpread(_objectSpread({}, widgetProps), {}, {
    matchId
  });
  window.SIR('addWidget', svs.sport.sportradar.getWidgetClassByMatchId(matchId, widgetType), widgetType, widgetProps, callback);
};
svs.sport.sportradar.removeWidgets = (matchId, widgetType) => {
  for (const {
    key,
    el
  } of widgetReferences) {
    if (key === svs.sport.sportradar.getKeyByMatchIdAndType(matchId, widgetType)) {
      window.SIR('removeWidget', el);
    }
  }
};
svs.sport.sportradar.doesWidgetTypeExist = widgetValue => {
  for (const key in svs.sport.sportradar.constants) {
    if (widgetValue === svs.sport.sportradar.constants[key]) {
      return true;
    }
  }
  return false;
};
svs.sport.sportradar.clearTimer = (matchId, widgetType) => {
  const key = svs.sport.sportradar.getKeyByMatchIdAndType(matchId, widgetType);
  if (interval[key]) {
    clearInterval(interval[key]);
    delete interval[key];
  }
};
svs.sport.sportradar.setupWidgetRefrence = (matchId, widgetType, el) => {
  widgetReferences.push({
    key: svs.sport.sportradar.getKeyByMatchIdAndType(matchId, widgetType, false),
    el
  });
};
svs.sport.sportradar.startTimer = (matchId, widgetType, widgetProps) => {
  const key = svs.sport.sportradar.getKeyByMatchIdAndType(matchId, widgetType);
  if (interval[key]) {
    svs.sport.sportradar.clearTimer(matchId, widgetType);
  }
  interval[key] = setInterval(() => svs.sport.sportradar.updateWidget(matchId, widgetType, widgetProps), tick);
};

 })(window);