(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sportradar/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/sport/sportradar/assets/javascripts/constants.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const sportRadarWidgetTypes = {
  LIVE_TABLE: 'season.liveTable',
  ATTACKING_PERFORMANCE_INDICATOR: 'match.attackingPerformanceIndicator',
  AVAILABLE_ODDS: 'match.availableOdds',
  AVG_GOALS_PER_PERIOD: 'match.avgGoalsPerPeriod',
  AVG_GOALS_SCORED_CONCEDED: 'match.avgGoalsScoredConceded',
  BALL_POSSESSION: 'match.ballPossession',
  COMMENTARY: 'match.commentary',
  CORRECT_SCORE_MATRIX: 'match.correctScoreMatrix',
  DEFENDING_PERFORMACE_INDICATOR: 'match.defendingPerformanceIndicator',
  FACTS_AND_FIGURES: 'match.factsandfigures',
  GENERAL_STATISTICS: 'match.generalStatistics',
  HEAD_TO_HEAD: 'headToHead.standalone',
  HEAD_TO_HEAD_BUTTON: 'HeadToHead.button',
  HEATMAP: 'match.heatmap',
  MATCH_LINEUPS: 'match.lineups',
  MATCH_LMT: 'match.lmt',
  MATCH_LMT_CUSTOM: 'match.lmtCustom',
  MATCH_LMT_LIGHT: 'match.lmtLight',
  MATCH_LMT_PLUS: 'match.lmtPlus',
  MATCH_LMT_STICKY: 'match.lmtSticky',
  MATCH_LIST: 'match.matchList',
  MATCH_REPLAY: 'match.matchReplay',
  MATCH_MOMENTUM: 'match.momentum',
  MATCH_ODDS: 'match.odds',
  MATCH_ODDS_VOTING: 'match.oddsVoting',
  MATCH_PREVIEW: 'match.preview',
  MATCH_SCOREBOARD: 'match.scoreboard',
  MATCH_SCOREBOARD_PROBABILITY: 'match.scoringProbabilityPerPeriod',
  MATCH_STATISTICS: 'match.statistics',
  MATCH_VERTICAL_TIMELINE: 'match.verticalTimeline',
  MATCH_WIN_PROBABILITY: 'match.winProbability',
  NBA_SEASON_CUP_ROSTER: 'nba.season.cupRoster'
};
if (svs.isServer) {
  module.exports = _objectSpread({}, sportRadarWidgetTypes);
} else {
  svs.sport.sportradar.constants = _objectSpread({}, sportRadarWidgetTypes);
}

 })(window);