(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sportradar/assets/javascripts/sir-to-element.js') >= 0) return;  svs.modules.push('/components/sport/sportradar/assets/javascripts/sir-to-element.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  log
} = svs.core;
const logger = new log.Logger('sir-api');
const {
  startSirScript,
  getWidgetClassByMatchId,
  doesWidgetTypeExist,
  startTimer,
  clearTimer
} = svs.sport.sportradar;
const addWidget = (targetElement, widgetType, widgetProps) => new Promise(resolve => {
  window.SIR('addWidget', targetElement, widgetType, widgetProps, () => {
    resolve();
  });
});
const updateWidgetToElement = (matchId, widgetType, widgetProps, element) => {
  widgetProps = _objectSpread(_objectSpread({}, widgetProps), {}, {
    matchId
  });
  const targetElement = element.querySelector(getWidgetClassByMatchId(matchId, widgetType));
  return addWidget(targetElement, widgetType, widgetProps);
};
const addWidgetByElement = async (matchId, widgetType, widgetProps, element) => {
  svs.sport.sportradar.setupWidgetRefrence(matchId, widgetType, element);
  return updateWidgetToElement(matchId, widgetType, widgetProps, element);
};
const createWidgetElement = (matchId, widgetType, parentElement, theme) => {
  const childClass = getWidgetClassByMatchId(matchId, widgetType);
  if (!parentElement.querySelector(childClass)) {
    const childElement = document.createElement('div');
    const classNames = ["".concat(String(getWidgetClassByMatchId(matchId, widgetType, false))), 'sr-sir-widget'];
    switch (theme) {
      case 'LIGHT':
        classNames.push('sportradar-theme-light');
        break;
      case 'AUTO':
        classNames.push('sportradar-theme-auto');
        break;
      default:
        classNames.push('sportradar-theme-dark');
        break;
    }
    childElement.className = classNames.join(' ');
    parentElement.appendChild(childElement);
  } else {
    throw new Error('Could not create widget, element already exists: ', childClass);
  }
};
const logError = err => {
  if (err) {
    logger.warn("".concat(err.toString(), "(").concat(err.line, ", ").concat(err.column, ")").concat(err.stack ? " : ".concat(err.stack.toString()) : ''));
  }
};

svs.sport.sportradar.tryStartWidgetToElement = async _ref => {
  let {
    matchId,
    widgetType,
    widgetProps,
    element,
    theme = 'DARK'
  } = _ref;
  if (!matchId || matchId.length < 1) {
    const errMsg = 'Cant startWidget without matchId';
    const err = new Error(errMsg);
    logger.warn(errMsg);
    throw err;
  } else if (!doesWidgetTypeExist(widgetType)) {
    throw new Error("WidgetType: ".concat(widgetType, " does not exist, please use widgetTypes from svs.sport.sportradar.constants"));
  } else if (!element) {
    const errMsg = 'Cant startWidget without a parent element';
    const err = new Error(errMsg);
    logger.warn(errMsg);
    throw err;
  }
  try {
    if (!window.__SIRP) {
      await startSirScript();
    }
    createWidgetElement(matchId, widgetType, element, theme);
    await addWidgetByElement(matchId, widgetType, widgetProps, element);
    startTimer(matchId, widgetType, widgetProps);
  } catch (err) {
    logError(err);
    throw err;
  }
};
svs.sport.sportradar.removeWidgetByElement = (matchId, widgetType, el) => {
  clearTimer(matchId, widgetType);
  const widgetEl = el.querySelector(getWidgetClassByMatchId(matchId, widgetType));
  if (widgetEl) {
    window.SIR('removeWidget', widgetEl);
    widgetEl.parentElement.removeChild(widgetEl);
  }
};

 })(window);